import NavBar from './NavBar';
import styled from '@emotion/styled';
import { colors } from 'src/utils/styles';

export default styled(NavBar)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${colors.secondary};
  padding: 12px 0;
  .navbar {
    display: flex;
    justify-content: space-between;
    &__start,
    &__end {
      display: flex;
      align-items: center;
    }
    &__brand {
      display: flex;
      margin: 0;
      & > a {
        display: flex;
      }
      & img {
        height: 42px;
      }
    }
    &__main-nav {
      margin: 0 3rem;
      display: flex;
    }
    &__link {
      color: ${colors.white};
      margin: 0 2rem;
      padding: 0.2rem 0;
      cursor: pointer;
      &--active {
        color: ${colors.white};
        border-bottom: 3px solid ${colors.tertiary};
      }
    }
    &__end > .navbar__link {
      font-size: 15px;
      margin: 0 0.5rem;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
  }
`;
