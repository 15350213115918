import { FC } from 'react';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { ListedProduct } from 'src/types';

type Props = {
  label: string;
  products: ListedProduct[];
  product?: ListedProduct;
  onChange: (product: ListedProduct) => void;
};

export const ProductSelector: FC<Props> = ({
  label,
  products,
  product,
  onChange,
}) => {
  const Product = ({ name }: { name: string }) => {
    return (
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <Box
          component="img"
          src="assets/images/logo.png"
          sx={{ maxWidth: '1.5em' }}
        />
        <Box component="span">{name}</Box>
      </Box>
    );
  };

  return (
    <nav>
      <FormControl fullWidth>
        <Box
          component="p"
          id="product-selector-label"
          aria-label="product-selector"
          sx={{ mb: 0.5 }}
        >
          {label}
        </Box>
        <Select
          size='small'
          sx={{ fontSize: '13px' }}
          labelId="product-selector-label"
          id="produt-selector"
          value={product?.id ?? ''}
          onChange={(event) => {
            const selectedProduct = products.find(
              (product) => product.id === event.target.value,
            );
            selectedProduct && onChange(selectedProduct);
          }}
        >
          {products?.map((product) => (
            <MenuItem
              sx={{ fontSize: '13px' }}
              key={`${product.id}_${product.version}`}
              value={product.id}
            >
              <Product {...product} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </nav>
  );
};
