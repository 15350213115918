import { createGlobalStyle } from 'styled-components';

const Style = createGlobalStyle`
  h1 {
    margin-top: 1em;
    font-weight: 600;
    font-size: 25px;
  }

  @keyframes loading { 
    0% {
      opacity: 0.2;
    }
  
    100% {
      opacity: 1;
    }
  }
`;

export default Style;
