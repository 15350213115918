export const localConfig: {
  [key: string]: {
    footer: string;
  };
} = {
  es_ES: {
    footer:
      'Para recibir asistencia técnica, asistencia de software o capacitación, comuníquese con <a href="mailto:support@idoven.atlassian.net">support@idoven.atlassian.net</a>.',
  },
  en_GB: {
    footer:
      'To receive technical support, software assistance, or training, please contact <a href="mailto:support@idoven.atlassian.net">support@idoven.atlassian.net</a>.',
  },
  en_US: {
    footer:
      'To receive technical support, software assistance, or training, please contact <a href="mailto: support@idoven.atlassian.net">support@idoven.atlassian.net</a>.',
  },
  fr_FR: {
    footer:
      'Pour obtenir une assistance technique, une assistance logicielle ou une formation, veuillez contacter <a href="mailto:support@idoven.atlassian.net">support@idoven.atlassian.net</a>.',
  },
};
