import styled from 'styled-components';

export const FooterStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002736 !important;
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  span {
    color: white;
    font-size: x-small;
  }
`;
