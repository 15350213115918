import { createGlobalStyle } from 'styled-components';
import { colors } from './idoven-theme';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
    background-color: ${colors.background};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  h1, h2, h3, h4 {
    color: ${colors.secondary};
  }

  h1 {
    font-weight: 400;
  }

  h2 {
    font-size: 19px;
    margin: 2rem 0 1rem;
  }

  h3 {
    margin: 0 0 1rem;
    font-size: 15px;
    color: ${colors.secondary};
  }

  h4 {
    margin: 0 0 0.25rem;
    font-size: 14px;
    color: ${colors.info};
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .subsection {
    color: ${colors.info};
    font-weight: 600;
  }
`;

export default GlobalStyle;
