import React from 'react';
import { FooterStyled } from './Footer.styled';
import { AppConfig } from 'src/types';

const Footer = (
  {appConfig}: {appConfig?: AppConfig;}
  ) => {
  return (
    <FooterStyled>
      <span dangerouslySetInnerHTML={{__html: appConfig?.footer ?? ''}}></span>
    </FooterStyled>
  );
};

export default Footer;