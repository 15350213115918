import axios from 'axios';
import { AppConfig, Selection, UserGuideType } from 'src/types';
import { EulaResponse } from 'src/types/eula';

const API_URL = process.env.REACT_APP_API_URL ?? '';

export const getAppConfig = (locale = '') => {
  //https://user-guides-api.staging.idoven.ai
  return axios
    .get<AppConfig>(`${API_URL}/translations/${locale ? `${locale}` : '' }`)
    .then(({ data }) => data);
}

export const getUserGuide = ({ locale, product }: Selection) =>{
  if (!product) {
    return;
  }

  const url = `${API_URL}/translations/${locale}/${product.id}/${product.version}`;
  
  return axios.get<UserGuideType>(url).then(({ data }) => data);
  
}

export const getEula = ({ locale, product }: Selection): Promise<EulaResponse> => {
  if (!product || !product.id) {
    return Promise.reject();
  }
  const url = `${API_URL}/translations/${locale}/${product.id}/${product.version}/eula`;
  return axios.get<EulaResponse>(url).then(({ data }) => data);
}

export const readEula = ({ locale, product }: Selection): Promise<EulaResponse> => {
  if (!product || !product.id) {
    return Promise.reject();
  }
  const url = `${API_URL}/translations/${locale}/${product.id}/${product.version}/eula`;
  return axios.post(url);
}