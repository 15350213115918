import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import { AuthInterceptorsProps } from "./AuthInterceptors.types";
import { InternalAxiosRequestConfig, AxiosError } from "axios";

const AuthInterceptors = ({
  axiosInstance,
  sessionStorageName,
  idleMinutes = 60,
  onUnauthorized,
}: AuthInterceptorsProps): JSX.Element => {
  const [open403Dialog, setOpen403Dialog] = useState<boolean>(false);
  const interceptorRequestId = useRef<null | number>(null);
  const interceptorResponseId = useRef<null | number>(null);

  const handleClose403Dialog = () => {
    setOpen403Dialog(false);
  };

  useEffect(() => {
    const unauthorized = () => {
      sessionStorage.removeItem(sessionStorageName);
      onUnauthorized();
    };

    interceptorRequestId.current = axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const token = sessionStorage.getItem(sessionStorageName);
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
      },
      (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
      }
    );

    interceptorResponseId.current = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 403) {
          setOpen403Dialog(true);
        }
        if (error?.response?.status === 401) {
          unauthorized();
        }
        return Promise.reject(error);
      }
    );

    let timeout: null | ReturnType<typeof setTimeout> = null;
    const resetIdleTime = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        unauthorized();
      }, 1000 * 60 * idleMinutes);
    };

    window.addEventListener("mousemove", resetIdleTime);
    window.addEventListener("keypress", resetIdleTime);

    return () => {
      if (interceptorRequestId.current)
        axiosInstance.interceptors.request.eject(interceptorRequestId.current);
      if (interceptorResponseId.current)
        axiosInstance.interceptors.request.eject(interceptorResponseId.current);
      window.removeEventListener("mousemove", resetIdleTime);
      window.removeEventListener("keypress", resetIdleTime);
    };
  }, [idleMinutes, onUnauthorized, axiosInstance, sessionStorageName]);

  return (
    <Dialog
      open={open403Dialog}
      onClose={handleClose403Dialog}
      aria-labelledby="forbidden-dialog-title"
      aria-describedby="forbidden-dialog-description"
    >
      <DialogTitle
        id="forbidden-dialog-title"
        style={{ display: "flex", alignItems: "center", gap: "10px" }}
      >
        Permiso denegado
        <LockIcon></LockIcon>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="forbidden-dialog-description">
          No tienes suficientes permisos para realizar esta acción. Contacte con
          el administrador.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose403Dialog}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthInterceptors;
