import Box from '@mui/material/Box';
import { ProductSelector } from './components/ProductSelector';
import { useState } from 'react';
import { getAppConfig, getUserGuide } from './utils/api';
import { useQuery } from 'react-query';
import { UserGuide } from './components/UserGuide';
import LanguageSelector2 from './components/LanguageSelector2';
import { generatePath, getSelectionFromUrlParameters } from './utils/url';
import { generateCacheProductId } from './utils/app';
import { AppConfig, Selection } from 'src/types';
import { Loading } from './components/Loading';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import config from 'src/config/keycloak/keycloak';
import AuthInterceptors from './components/AuthInterceptors';
import { Layout } from './components/Layout';
import { localConfig } from './assets/locales/locales';

const DEFAULT_LOCALE = 'en_GB';
export function App() {
  const keycloak = useKeycloak().keycloak;
  const onUnauthorized = () => {
    keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  const urlSelection = getSelectionFromUrlParameters({
    locale: DEFAULT_LOCALE,
  });

  const [selection, setSelection] = useState<Selection>(urlSelection);
  const updateSelection = (newSelection: Partial<Selection>) => {
    setSelection((selection) => {
      const updatedSelection = { ...selection, ...newSelection };

      const productPath = generatePath(updatedSelection);
      window.history.pushState({}, '', `/?${productPath}`);

      return updatedSelection;
    });
  };

  let { data: appConfig } = useQuery({
    queryKey: ['userGuides', selection.locale],
    refetchOnWindowFocus: false,
    queryFn: () => getAppConfig(selection.locale),
  });
  appConfig = {...appConfig, ...localConfig[selection.locale ?? DEFAULT_LOCALE] ?? {}} as AppConfig;

  const { data: userGuide, isLoading: isProductLoading } = useQuery({
    queryKey: ['userGuide', generateCacheProductId(selection)],
    refetchOnWindowFocus: false,
    queryFn: () => selection.product && getUserGuide(selection),
    enabled: !!selection?.product?.id,
  });

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{ minHeight: '100vh', height: '100%', fontSize: '13px' }}
    >
      <AuthInterceptors
        axiosInstance={axios}
        idleMinutes={
          config.IDLE_MINUTES !== undefined ? +config.IDLE_MINUTES : undefined
        }
        sessionStorageName={config.SESSION_STORAGE_AUTH_NAME}
        onUnauthorized={onUnauthorized}
      />
      <Layout appConfig={appConfig}>
        {appConfig ? (
          <Box sx={{ pb: 4 }}>
            <>
              <Box sx={{ mt: 2, maxWidth: '20em' }}>
                {appConfig.locale_selector && (
                  <LanguageSelector2
                    label={appConfig.locale_selector}
                    locale={selection.locale ?? ''}
                    locales={appConfig?.locale_selection_options ?? []}
                    onChange={(locale) => updateSelection({ locale })}
                  />
                )}
              </Box>
              <Box component="h1">{appConfig.header}</Box>
              <Box component="p" fontSize={'17px'}>
                {appConfig.description}
              </Box>
              <Box sx={{ maxWidth: '20em' }}>
                <ProductSelector
                  label={appConfig.product_selector}
                  product={selection.product}
                  products={appConfig.products ?? []}
                  onChange={(product) => updateSelection({ product })}
                />
              </Box>
            </>
            {isProductLoading && <Loading size="small" />}

            {userGuide && selection.product && (
              <Box component="article">
                <UserGuide
                  userGuide={{ ...selection.product, ...userGuide }}
                  locale={selection.locale ?? DEFAULT_LOCALE}
                  appConfig={appConfig}
                  product={selection.product}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Loading size="large" />
        )}
      </Layout>
    </Box>
  );
}

export default App;
