import { Selection } from 'src/types';
import queryString from 'query-string';

export const generatePath = ({
  locale,
  product
}: Partial<Selection>) => {
  const params = {
    locale,
    ...product
  };

  return queryString.stringify(params);
};

export const getSelectionFromUrlParameters = (
  defaultSelection: Partial<Selection>
): Selection => {
  const parameters = new URLSearchParams(window.location.search);

  const name = parameters.get('name') || defaultSelection?.product?.name;
  const id = parameters.get('id') || defaultSelection?.product?.id;
  const version =
    parameters.get('version') || defaultSelection?.product?.version;
  const locale = parameters.get('locale') || defaultSelection?.locale;

  const isValidProduct = name && id && version;

  const product = isValidProduct ? { name, version, id } : undefined;

  return {
    locale,
    product,
  };
};
