import MenuItem from '@mui/material/MenuItem';
import { FC } from 'react';
import { Box, FormControl, Select } from '@mui/material';
import { Locale } from 'src/types';
import { LOCALE_FLAG } from 'src/utils/constants';

type Props = {
  label: string;
  locale: string;
  onChange: (locale: string) => void;
  locales: Locale[];
};

export const LanguageSelector2: FC<Props> = ({
  label,
  locale,
  onChange,
  locales,
}) => {
  const Language = ({ localeId }: { localeId: string }) => {
    const locale = locales.find((listedLocale) => listedLocale.id === localeId);

    if (!locale) {
      return null;
    }

    return (
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        {locale.name}{' '}
        <Box component="span" sx={{ fontSize: 25 }}>
          {LOCALE_FLAG[locale.id]}
        </Box>
      </Box>
    );
  };

  return (
    <nav>
      <FormControl fullWidth>
        <Box
          component="p"
          id="locale-selector-label"
          aria-label="locale-selector"
          sx={{ mb: 0.5 }}
        >
          {label}
        </Box>
        <Select
          size='small'
          sx={{ fontSize: '13px' }}
          labelId="locale-selector-label"
          id="locale-selector"
          value={locale}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          renderValue={(value) => <Language localeId={value} />}
        >
          {locales?.map((listedLocale) => (
            <MenuItem
              sx={{ fontSize: '13px' }}
              key={listedLocale.id}
              value={listedLocale.id}
            >
              <Language localeId={listedLocale.id} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </nav>
  );
};
