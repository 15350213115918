import { css } from 'styled-components';

const GRID = {
  DISPLAY: css`
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
  `,
  COLUMN_12: css`
    grid-column: 1 / span 4;
  `,
};

export default GRID;
