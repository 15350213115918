import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme, GlobalStyle, Style } from 'src/utils/styles';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import LogoIcon from 'src/assets/images/logo-icon_small.png';
import { AuthClientTokens } from '@react-keycloak/core';

const tokenLogger = ({ token }: AuthClientTokens) => {
  if (!token) return;
  sessionStorage.setItem('keycloakToken', token);
};

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  pkceMethod: 'S256',
  checkLoginIframe: false,
  // enableLogging: true, // For debugging purposes
};

const handleLoadingComponent = () => {
  return (
    <div
      style={{
        margin: 0,
        backgroundColor: '#002735',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img height="150" src={LogoIcon} alt="Idoven logo icon" />
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const WrappedApp = () => {
  const [queryClient] = useState(new QueryClient());

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

root.render(
  <>
    <GlobalStyle />
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      LoadingComponent={handleLoadingComponent()}
      onTokens={tokenLogger}
    >
      <React.StrictMode>
        <Style />
        <WrappedApp />
      </React.StrictMode>
    </ReactKeycloakProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
