import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppConfig, ListedProduct } from 'src/types';
import { getEula, readEula } from 'src/utils/api';
import { EulaResponse } from 'src/types/eula';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import { colors } from 'src/utils/styles/idoven-theme';
import EulaStyled from './eulaStyled';

export type LicenseAgreementProps = {
  locale: string;
  appConfig: AppConfig;
  product: ListedProduct;
};

const LicenseAgreement: React.FC<LicenseAgreementProps> = ({
  locale,
  appConfig,
  product,
}) => {
  const [open, setOpen] = React.useState(false);
  const [eula, setEula] = React.useState<null | EulaResponse>(null);
  const [loading, setLoading] = React.useState(false);

  const loadEula = ({
    locale,
    product,
  }: {
    locale: string;
    product: ListedProduct;
  }) => {
    setLoading(true);
    return getEula({ locale, product }).then((eula) => {
      setEula(eula);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!product || !locale) return;
    loadEula({ locale, product });
  }, [locale, product]);

  const handleViewEula = () => {
    setOpen(true);
  };

  const handleReadEula = () => {
    setLoading(true);
    readEula({ locale, product })
      .then(() => {
        if (eula) setEula({ ...eula, signed: true });
        loadEula({ locale, product });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const dialogContent = (
    <Dialog
      open={open}
      maxWidth={'md'}
      onClose={() => setOpen(false)}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {eula && (
        <>
          <DialogTitle id="scroll-dialog-title">{eula.heading}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              component={'div'}
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <h4>{eula.subheading}</h4>
              <EulaStyled dangerouslySetInnerHTML={{ __html: eula.body }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {eula.signed && <DoneIcon sx={{ color: colors.primary }} />}
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                variant="contained"
                disabled={loading || eula.signed}
                onClick={() => {
                  handleReadEula();
                }}
              >
                {eula.agreement_text}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
  return (
    <>
      {eula ? (
        <>
          <h2>{eula.heading}</h2>
          <TableContainer>
            <Table aria-label="eula table">
              <TableHead>
                <TableRow>
                  <TableCell>{appConfig.product_header}</TableCell>
                  <TableCell align="right">
                    {appConfig.version_header}
                  </TableCell>
                  <TableCell align="right">
                    {appConfig.agreement_header}
                  </TableCell>
                  <TableCell align="right">
                    {appConfig.actions_header}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {product.name}
                  </TableCell>
                  <TableCell align="right">{product.version}</TableCell>
                  <TableCell align="right">
                    {eula.timestamp
                      ? new Date(eula.timestamp + 'Z').toLocaleString()
                      : '-'}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleViewEula}
                    >
                      {eula.view_agreement_button}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ fontSize: '22px' }}>{dialogContent}</Box>
        </>
      ) : (
        <Box
          sx={{ fontSize: '22px', display: 'flex', justifyContent: 'center' }}
        >
          {<CircularProgress />}
        </Box>
      )}
    </>
  );
};

export default LicenseAgreement;
