import Box from "@mui/material/Box";
import { FC } from "react";
import logo from "src/assets/images/logo.png";

type Props = {
  size: "small" | "large";
};

export const Loading: FC<Props> = ({ size }) => {
  const sx =
    size === "large"
      ? {
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }
      : {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "2em",
          img: {
            maxWidth: "5em",
          },
        };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        animation: "loading 1s infinite",
        ...sx,
      }}
    >
      <Box component="img" src={logo} alt="logo" sx={{ maxWidth: "10em" }} />
    </Box>
  );
};
