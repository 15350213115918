import React from 'react';
import logo from 'src/assets/images/logo_small.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Container from '@mui/material/Container';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import { AppConfig } from 'src/types';

const NavBar = ({
  className,
  appConfig,
}: {
  className?: string;
  appConfig?: AppConfig;
}) => {
  const { keycloak } = useKeycloak();
  const handleLogout = () => {
    localStorage.removeItem('keycloakToken');
    keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  return (
    <Box sx={{ boxShadow: 5 }} className={className} data-testid="navbar">
      <Container className="navbar" fixed>
        <div className="navbar__start">
          <div className="navbar__brand">
            <img src={logo} alt="Logo Idoven" />
          </div>
        </div>
        <div className="navbar__end">
          <span onClick={handleLogout} className="navbar__link">
            <ExitToAppIcon fontSize="small" /> {appConfig?.logout_button}
          </span>
        </div>
      </Container>
    </Box>
  );
};

export default NavBar;
