import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import styled from '@emotion/styled';
import NavBar from '../NavBar/styled';
import Box from '@mui/material/Box';
import Footer from '../Footer';
import { AppConfig } from 'src/types';

const HEADER_HEIGHT = '66px';
const FOOTER_HEIGHT = '30px';

const Layout = ({ children, appConfig }: { children: ReactNode, appConfig?: AppConfig }): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <NavBar appConfig={appConfig}/>
      <LayoutStyle>
        <Container sx={{ backgroundColor: '#F8FAFC;', height: '100%' }} fixed>
          {children}
        </Container>
      </LayoutStyle>
      <Footer appConfig={appConfig}/>
    </Box>
  );
};

const LayoutStyle = styled.div`
  margin-top: ${HEADER_HEIGHT};
  height: calc(100% - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  background-color: #F8FAFC;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  appConfig: PropTypes.object,
};

export default Layout;
