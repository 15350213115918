// @ts-nocheck

import { createTheme } from '@mui/material/styles';
import { enUS } from '@mui/material/locale';

// Theme colors
// #F2F2F2
// #BBDFE4
// #47B2C1
// #002735
// #68D1E0
// #FC8C5C

const colors = {
  primary: '#47B2C1',
  secondary: '#002735',
  tertiary: '#FC8C5C',
  white: '#FFFFFF',
  error: '#ef5350',
  info: '#7B7B7B',
  background: '#f8fafc',
  lightGrey: '#F0F2F4',
  lightPrimary: '#BBDFE4',
  borderColor: 'rgba(0, 0, 0, 0.12)',
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
    },
    tertiary: {
      main: colors.tertiary,
      contrastText: colors.white,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.tertiary,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.primary,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle2: {
      color: colors.info,
    },
    body2: {
      fontSize: '15px',
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colors.error,
          '&$error': {
            color: colors.error,
          },
        },
      },
    },
  },
}, enUS);

export { colors, theme };
