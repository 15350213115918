import { Box, Grid, Link, List, ListItem } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { AppConfig, ListedProduct } from 'src/types';
import { UserGuideType } from 'src/types/userGuide';
import StyleContainer from './style';
import { LicenseAgreement } from '../LicenseAgreement';

type Props = {
  userGuide: ListedProduct & UserGuideType;
  appConfig: AppConfig;
  locale: string;
  product: ListedProduct;
};

export const UserGuide: FC<Props> = ({
  userGuide,
  appConfig,
  locale,
  product,
}) => {
  const {
    name,
    links,
    medical_device,
    warnings,
    cautions,
    contraindications,
    ce,
  } = userGuide;

  const reactMarkdownConfig = {
    components: {
      a: (props: { href?: string; children: ReactNode }) => {
        return (
          <a href={props.href ?? '/'} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        );
      },
    },
  };

  const details = [];

  if (warnings.length > 0) {
    details.push({
      icon: 'warning',
      items: warnings,
    });
  }

  if (cautions.length > 0) {
    details.push({
      icon: 'cautions',
      items: cautions,
    });
  }

  if (contraindications.length > 0) {
    details.push({
      icon: 'contraindications',
      items: contraindications,
    });
  }

  if (ce) {
    details.push({
      icon: 'ce',
      items: [{ value: ce }],
    });
  }
  return (
    <StyleContainer>
      <Box component="h1">{name}</Box>

      <nav>
        <List>
          {links.map((link) => (
            <ListItem key={link.label} sx={{ pl: 0 }}>
              <Link href={link.href} target={'_blank'} fontSize={'18px'}>
                {link.label}
              </Link>
            </ListItem>
          ))}
        </List>
      </nav>
      <LicenseAgreement
        locale={locale}
        product={product}
        appConfig={appConfig}
      />
      {medical_device.length > 0 && (
        <section>
          <h2>{appConfig.medical_device_header}</h2>
          <Grid container spacing={4} justifyContent={'flex-start'}>
            {medical_device.map((item, index) => {
              const items = Array.isArray(item.value)
                ? item.value
                : [item.value];
              return (
                <Grid
                  key={item.id}
                  item
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  columnGap={4}
                  xs={12}
                  md={index % 2 === 0 ? 5 : 7}
                >
                  <Box
                    component="img"
                    src={`assets/marks/${item.id}.png`}
                    sx={{ maxWidth: '5em', width: '5m' }}
                  />

                  <Box
                    sx={{
                      borderRadius: '10px',
                      padding: '0 1em',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      m: 0,
                    }}
                  >
                    <ReactMarkdown {...reactMarkdownConfig}>
                      {items.join('\n\n')}
                    </ReactMarkdown>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </section>
      )}

      {details.length > 0 && (
        <section>
          <Grid container spacing={1} sx={{ mt: 2, alignItems: 'flex-start' }}>
            {details.map((detail) => {
              return (
                <Grid
                  key={detail.icon}
                  item
                  component="section"
                  display={'flex'}
                  columnGap={3}
                  xs={12}
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={`assets/marks/${detail.icon}.png`}
                    sx={{
                      width: '5m',
                      maxWidth: '5em',
                    }}
                  />
                  <Box
                    sx={{
                      height: '100%',
                      borderRadius: '10px',
                      padding: '1em',
                    }}
                  >
                    <ReactMarkdown {...reactMarkdownConfig}>
                      {detail.items
                        .map(
                          (item) =>
                            `${item?.key ? `- **${item.key}**:` : ''} ${
                              item.value
                            }`,
                        )
                        .join('\n')}
                    </ReactMarkdown>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </section>
      )}
    </StyleContainer>
  );
};
