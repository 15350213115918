import { colors } from "src/utils/styles";
import styled from "styled-components";

export default styled.div`
  a,
  a:hover,
  a:visited,
  a:active {
    color: ${colors.primary};
    text-decoration: underline;
  }

  nav {
    ul {
      padding: 0;

      li {
        font-size: 1.3em;
        font-weight: 600;
        padding-left: 0;
      }
    }
  }

  section {
    ul {
      margin: 0;
      padding: 0 0 0 1em;

      li + li {
        margin-top: 1em;
      }
    }
  }

  p,
  li {
    //word-break: break-all; // UDI value is a long string
    margin: 0;
    text-align: justify;
    line-height: 2em;
  }
`;
