import styled from '@emotion/styled';

const EulaStyled = styled('div')`
  font-size: 14px;
  color: #333;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333;
  }
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 14px;
    font-weight: 500;
  }
  li {
    margin-bottom: 5px;
  }
`;

export default EulaStyled;
